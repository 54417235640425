import React, { FC, useContext } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { LoginPage } from '../../app/auth/LoginPage';
import { Register } from '../../app/auth/RegisterPage';
import { HomePage } from '../../app/home/HomePage';
import { AccountPage } from '../../app/account/AccountPage';
import { CustomersPage } from '../../app/customers/CustomersPage';
import MainLayout from '../Layout/MainLayout';
import AuthLayout from '../Layout/AuthLayout';
import { AuthContext } from '../auth/authContext';
import AccountDetails from '../../app/account/components/accountDetails';
import AccountDetailsEdit from '../../app/account/components/accountDetailsEdit';
import CustomersGrid from '../../app/customers/components/CustomersGrid';
import NoCustomers from '../../app/customers/components/noCustomers';
import CustomerDetailsWrapper from '../../app/customers/components/CustomerDetailsWrapper';

const AppRoutes: FC = () => {
  const currentUser = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Routes>
        {currentUser ? (
          <>
            {/* Main Layout */}
            <Route path="/" element={<MainLayout />}>
              {/* Home Route */}
              <Route index element={<HomePage />} />

              {/* Account Routes */}
              <Route path="account" element={<AccountPage />}>
                <Route path="overview" element={<AccountDetails />} />
                <Route path="settings" element={<AccountDetailsEdit />} />
              </Route>

              {/* Customers Routes */}
              <Route path="customers" element={<CustomersPage />}>
                <Route path="list" element={<CustomersGrid />} />
                <Route path="welcome" element={<NoCustomers />} />
                <Route path=":customerId" element={<CustomerDetailsWrapper />} />
              </Route>

              {/* Catch-all for authenticated users */}
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate to="/login" />} />

            {/* Auth Layout */}
            <Route path="/" element={<AuthLayout />}>
              <Route path="login" element={<LoginPage />} />
              <Route path="register" element={<Register />} />
            </Route>

            {/* Catch-all for unauthenticated users */}
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
