import React, { useEffect, useState} from 'react';
import { useAppContext } from '../../context/AppContext';
import useWindowWidth from '../utils/windowWidth';
import { NavLink } from "react-router-dom";

export const Sidebar = () => {
    const { isSidebarActive, toggleSidebarActiveState, activateSideBar } = useAppContext();
    const [isDashboardHovered, setIsDashboardHovered] = useState(true);
    const [isAccountHovered, setIsAccountHovered] = useState(false);
    const [isCustomerHovered, setIsCustomerHovered] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleDashboardHoverClick = () => {
        setIsDashboardHovered(!isDashboardHovered);
      };
    const handleAccountHoverClick = () => {
        setIsAccountHovered(!isAccountHovered);
    }; 
    const handleCustomerHoverClick = () => {
        setIsCustomerHovered(!isCustomerHovered);
    };            

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
      };

    return (
        
        <div id="kt_app_sidebar" className={`app-sidebar flex-column drawer drawer-start ${isSidebarActive ? 'drawer-on' : ''}`} data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
               
        {/* <!--begin::Logo-->*/}
        <div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
            {/* <!--begin::Logo image-->*/}
            <a href="index.html">
                <img alt="Logo" src="/assets/media/logos/default-dark.svg" className="h-25px app-sidebar-logo-default" />
                <img alt="Logo" src="/assets/media/logos/default-small.svg" className="h-20px app-sidebar-logo-minimize" />
            </a>
            {/* <!--end::Logo image-->*/}
            {/* <!--begin::Sidebar toggle-->*/}
            {/* <!--begin::Minimized sidebar setup:
            if (isset($_COOKIE["sidebar_minimize_state"]) && $_COOKIE["sidebar_minimize_state"] === "on") { 
            1. "src/js/layout/sidebar.js" adds "sidebar_minimize_state" cookie value to save the sidebar minimize state.
            2. Set data-kt-app-sidebar-minimize="on" attribute for body tag.
            3. Set data-kt-toggle-state="active" attribute to the toggle element with "kt_app_sidebar_toggle" id.
            4. Add "active" class to to sidebar toggle element with "kt_app_sidebar_toggle" id.
            }
            -->*/}
            <div id="kt_app_sidebar_toggle" className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary h-30px w-30px position-absolute top-50 start-100 translate-middle rotate" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-minimize">
                <i className="ki-duotone ki-black-left-line fs-3 rotate-180">
                    <span className="path1"></span>
                    <span className="path2"></span>
                </i>
            </div>
            {/* <!--end::Sidebar toggle-->*/}
        </div>
        {/* <!--end::Logo-->*/}
        {/* <!--begin::sidebar menu-->*/}
        <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
            {/* <!--begin::Menu wrapper-->*/}
            <div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper">
                {/* <!--begin::Scroll wrapper-->*/}
                <div id="kt_app_sidebar_menu_scroll" className="scroll-y my-5 mx-3" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer" data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true">
                    {/* <!--begin::Menu-->*/}
                    <div className="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                        {/* <!--begin:Menu item-->*/}
                        <div data-kt-menu-trigger="click" className={`menu-item here menu-accordion ${isDashboardHovered ? "hover show" : ""}`}>
                            {/* <!--begin:Menu link-->*/}
                            <span className="menu-link" onClick={handleDashboardHoverClick}>
                                <span className="menu-icon">
                                    <i className="ki-duotone ki-element-11 fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                    </i>
                                </span>
                                <span className="menu-title">Dashboards</span>
                                <span className="menu-arrow"></span>
                            </span>
                            {/* <!--end:Menu link-->*/}
                            {/* <!--begin:Menu sub-->*/}
                            <div className="menu-sub menu-sub-accordion">
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    <NavLink to="/" className={({ isActive }) => isActive ? "menu-link active" : "menu-link"}>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Default</span>
                                    </NavLink>
                                </div>
                                {/* <!--end:Menu item-->*/}         
                                <div className={`menu-inner flex-column ${isCollapsed ? "collapse" : "show"}`} id="kt_app_sidebar_menu_dashboards_collapse">
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="dashboards/bidding.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Bidding</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="dashboards/pos.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">POS System</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="dashboards/call-center.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Call Center</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="dashboards/logistics.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Logistics</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="dashboards/website-analytics.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Website Analytics</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="dashboards/finance-performance.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Finance Performance</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="dashboards/store-analytics.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Store Analytics</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="dashboards/social.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Social</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="dashboards/delivery.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Delivery</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="dashboards/crypto.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Crypto</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="dashboards/school.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">School</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="dashboards/podcast.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Podcast</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                    {/* <!--begin:Menu item-->*/}
                                    <div className="menu-item">
                                        {/* <!--begin:Menu link-->*/}
                                        <a className="menu-link" href="landing.html">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Landing</span>
                                        </a>
                                        {/* <!--end:Menu link-->*/}
                                    </div>
                                    {/* <!--end:Menu item-->*/}
                                </div>
                                <div className="menu-item">
                                    <div className="menu-content">
                                        <a className="btn btn-flex btn-color-primary d-flex flex-stack fs-base p-0 ms-2 mb-2 toggle collapsible collapsed" data-bs-toggle="collapse" href="#kt_app_sidebar_menu_dashboards_collapse" data-kt-toggle-text="Show Less" 
                                        onClick={handleToggle}>
                                            <span data-kt-toggle-text-target="true"> {isCollapsed ? "Show 12 More" : "Show Less"}</span>
                                            <i className="ki-duotone ki-minus-square toggle-on fs-2 me-0">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </i>
                                            <i className="ki-duotone ki-plus-square toggle-off fs-2 me-0">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end:Menu sub-->*/}
                        </div>
                        {/* <!--end:Menu item-->*/}
                        {/* <!--begin:Menu item-->*/}
                        <div className="menu-item pt-5">
                            {/* <!--begin:Menu content-->*/}
                            <div className="menu-content">
                                <span className="menu-heading fw-bold text-uppercase fs-7">Apps</span>
                            </div>
                            {/* <!--end:Menu content-->*/}
                        </div>
                        {/* <!--end:Menu item-->*/}
                        {/* <!--begin:Menu item-->*/}
                        <div data-kt-menu-trigger="click" className={`menu-item menu-lg-down-accordion menu-sub-lg-down-indention ${isAccountHovered ? "hover show" : ""}`}>
                            {/* <!--begin:Menu link-->*/}
                            <span className="menu-link"  onClick={handleAccountHoverClick}>
                                <span className="menu-icon">
                                    <i className="ki-duotone ki-element-plus fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                        <span className="path5"></span>
                                    </i>
                                </span>
                                <span className="menu-title">Account</span>
                                <span className="menu-arrow"></span>
                            </span>
                            {/* <!--end:Menu link-->*/}
                            {/* <!--begin:Menu sub-->*/}
                            <div className="menu-sub menu-sub-accordion">
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <NavLink to="/account/overview" className={({ isActive }) => isActive ? "menu-link active" : "menu-link"}>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Overview</span>
                                    </NavLink>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <NavLink to="/account/settings" className={({ isActive }) => isActive ? "menu-link active" : "menu-link"}>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Settings</span>
                                    </NavLink>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                            </div>
                            {/* <!--end:Menu sub-->*/}
                        </div>
                        {/* <!--end:Menu item-->*/}
                        {/* <!--begin:Menu item-->*/}
                        <div data-kt-menu-trigger="click" className={`menu-item menu-lg-down-accordion menu-sub-lg-down-indention ${isCustomerHovered ? "hover show" : ""}`}>
                            {/* <!--begin:Menu link-->*/}
                            <span className="menu-link" onClick={handleCustomerHoverClick}>
                                <span className="menu-icon">
                                    <i className="ki-duotone ki-user fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </span>
                                <span className="menu-title">CRM</span>
                                <span className="menu-arrow"></span>
                            </span>
                            {/* <!--end:Menu link-->*/}
                            {/* <!--begin:Menu sub-->*/}
                            <div className="menu-sub menu-sub-accordion">
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <NavLink to="/customers/welcome" className={({ isActive }) => isActive ? "menu-link active" : "menu-link"}>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Customers</span>
                                    </NavLink>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                {/* <!--begin:Menu link-->*/}
                                    <NavLink to="/customers/list" className={({ isActive }) => isActive ? "menu-link active" : "menu-link"}>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Customer List</span>
                                    </NavLink>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="authentication/general/verify-email.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Verify Email</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="authentication/general/coming-soon.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Coming Soon</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="authentication/general/password-confirmation.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Password Confirmation</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="authentication/general/account-deactivated.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Account Deactivation</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="authentication/general/error-404.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Error 404</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="authentication/general/error-500.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Error 500</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                            </div>
                            {/* <!--end:Menu sub-->*/}
                        </div>
                        {/* <!--end:Menu item-->*/}
                        {/* <!--begin:Menu item-->*/}
                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention">
                            {/* <!--begin:Menu link-->*/}
                            <span className="menu-link">
                                <span className="menu-icon">
                                    <i className="ki-duotone ki-file fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </span>
                                <span className="menu-title">Corporate</span>
                                <span className="menu-arrow"></span>
                            </span>
                            {/* <!--end:Menu link-->*/}
                            {/* <!--begin:Menu sub-->*/}
                            <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-2 py-4 w-200px mh-75 overflow-auto">
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/about.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">About</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/team.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Our Team</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/contact.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Contact Us</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/licenses.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Licenses</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/sitemap.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Sitemap</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                            </div>
                            {/* <!--end:Menu sub-->*/}
                        </div>
                        {/* <!--end:Menu item-->*/}
                        {/* <!--begin:Menu item-->*/}
                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                            {/* <!--begin:Menu link-->*/}
                            <span className="menu-link">
                                <span className="menu-icon">
                                    <i className="ki-duotone ki-abstract-39 fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </span>
                                <span className="menu-title">Social</span>
                                <span className="menu-arrow"></span>
                            </span>
                            {/* <!--end:Menu link-->*/}
                            {/* <!--begin:Menu sub-->*/}
                            <div className="menu-sub menu-sub-accordion">
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/social/feeds.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Feeds</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/social/activity.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Activty</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/social/followers.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Followers</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/social/settings.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Settings</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                            </div>
                            {/* <!--end:Menu sub-->*/}
                        </div>
                        {/* <!--end:Menu item-->*/}
                        {/* <!--begin:Menu item-->*/}
                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                            {/* <!--begin:Menu link-->*/}
                            <span className="menu-link">
                                <span className="menu-icon">
                                    <i className="ki-duotone ki-bank fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </span>
                                <span className="menu-title">Blog</span>
                                <span className="menu-arrow"></span>
                            </span>
                            {/* <!--end:Menu link-->*/}
                            {/* <!--begin:Menu sub-->*/}
                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/blog/home.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Blog Home</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/blog/post.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Blog Post</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                            </div>
                            {/* <!--end:Menu sub-->*/}
                        </div>
                        {/* <!--end:Menu item-->*/}
                        {/* <!--begin:Menu item-->*/}
                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                            {/* <!--begin:Menu link-->*/}
                            <span className="menu-link">
                                <span className="menu-icon">
                                    <i className="ki-duotone ki-chart-pie-3 fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                                <span className="menu-title">FAQ</span>
                                <span className="menu-arrow"></span>
                            </span>
                            {/* <!--end:Menu link-->*/}
                            {/* <!--begin:Menu sub-->*/}
                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/faq/classic.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">FAQ Classic</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/faq/extended.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">FAQ Extended</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                            </div>
                            {/* <!--end:Menu sub-->*/}
                        </div>
                        {/* <!--end:Menu item-->*/}
                        {/* <!--begin:Menu item-->*/}
                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                            {/* <!--begin:Menu link-->*/}
                            <span className="menu-link">
                                <span className="menu-icon">
                                    <i className="ki-duotone ki-bucket fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                    </i>
                                </span>
                                <span className="menu-title">Pricing</span>
                                <span className="menu-arrow"></span>
                            </span>
                            {/* <!--end:Menu link-->*/}
                            {/* <!--begin:Menu sub-->*/}
                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/pricing.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Column Pricing</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/pricing/table.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Table Pricing</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                            </div>
                            {/* <!--end:Menu sub-->*/}
                        </div>
                        {/* <!--end:Menu item-->*/}
                        {/* <!--begin:Menu item-->*/}
                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                            {/* <!--begin:Menu link-->*/}
                            <span className="menu-link">
                                <span className="menu-icon">
                                    <i className="ki-duotone ki-call fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                        <span className="path5"></span>
                                        <span className="path6"></span>
                                        <span className="path7"></span>
                                        <span className="path8"></span>
                                    </i>
                                </span>
                                <span className="menu-title">Careers</span>
                                <span className="menu-arrow"></span>
                            </span>
                            {/* <!--end:Menu link-->*/}
                            {/* <!--begin:Menu sub-->*/}
                            <div className="menu-sub menu-sub-accordion">
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/careers/list.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Careers List</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="pages/careers/apply.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Careers Apply</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                            </div>
                            {/* <!--end:Menu sub-->*/}
                        </div>
                        {/* <!--end:Menu item-->*/}
                        {/* <!--begin:Menu item-->*/}
                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                            {/* <!--begin:Menu link-->*/}
                            <span className="menu-link">
                                <span className="menu-icon">
                                    <i className="ki-duotone ki-color-swatch fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                        <span className="path5"></span>
                                        <span className="path6"></span>
                                        <span className="path7"></span>
                                        <span className="path8"></span>
                                        <span className="path9"></span>
                                        <span className="path10"></span>
                                        <span className="path11"></span>
                                        <span className="path12"></span>
                                        <span className="path13"></span>
                                        <span className="path14"></span>
                                        <span className="path15"></span>
                                        <span className="path16"></span>
                                        <span className="path17"></span>
                                        <span className="path18"></span>
                                        <span className="path19"></span>
                                        <span className="path20"></span>
                                        <span className="path21"></span>
                                    </i>
                                </span>
                                <span className="menu-title">Utilities</span>
                                <span className="menu-arrow"></span>
                            </span>
                            {/* <!--end:Menu link-->*/}
                            {/* <!--begin:Menu sub-->*/}
                            <div className="menu-sub menu-sub-accordion">
                                {/* <!--begin:Menu item-->*/}
                                <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                    {/* <!--begin:Menu link-->*/}
                                    <span className="menu-link">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Modals</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    {/* <!--end:Menu link-->*/}
                                    {/* <!--begin:Menu sub-->*/}
                                    <div className="menu-sub menu-sub-accordion">
                                        {/* <!--begin:Menu item-->*/}
                                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                            {/* <!--begin:Menu link-->*/}
                                            <span className="menu-link">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">General</span>
                                                <span className="menu-arrow"></span>
                                            </span>
                                            {/* <!--end:Menu link-->*/}
                                            {/* <!--begin:Menu sub-->*/}
                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/general/invite-friends.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Invite Friends</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/general/view-users.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">View Users</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/general/select-users.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Select Users</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/general/upgrade-plan.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Upgrade Plan</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/general/share-earn.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Share & Earn</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                            </div>
                                            {/* <!--end:Menu sub-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                            {/* <!--begin:Menu link-->*/}
                                            <span className="menu-link">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Forms</span>
                                                <span className="menu-arrow"></span>
                                            </span>
                                            {/* <!--end:Menu link-->*/}
                                            {/* <!--begin:Menu sub-->*/}
                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/forms/new-target.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">New Target</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/forms/new-card.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">New Card</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/forms/new-address.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">New Address</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/forms/create-api-key.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Create API Key</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/forms/bidding.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Bidding</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                            </div>
                                            {/* <!--end:Menu sub-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                            {/* <!--begin:Menu link-->*/}
                                            <span className="menu-link">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Wizards</span>
                                                <span className="menu-arrow"></span>
                                            </span>
                                            {/* <!--end:Menu link-->*/}
                                            {/* <!--begin:Menu sub-->*/}
                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/wizards/create-app.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Create App</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/wizards/create-campaign.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Create Campaign</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/wizards/create-account.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Create Business Acc</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/wizards/create-project.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Create Project</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/wizards/top-up-wallet.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Top Up Wallet</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/wizards/offer-a-deal.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Offer a Deal</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/wizards/two-factor-authentication.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Two Factor Auth</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                            </div>
                                            {/* <!--end:Menu sub-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                            {/* <!--begin:Menu link-->*/}
                                            <span className="menu-link">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Search</span>
                                                <span className="menu-arrow"></span>
                                            </span>
                                            {/* <!--end:Menu link-->*/}
                                            {/* <!--begin:Menu sub-->*/}
                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/search/users.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Users</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                                {/* <!--begin:Menu item-->*/}
                                                <div className="menu-item">
                                                    {/* <!--begin:Menu link-->*/}
                                                    <a className="menu-link" href="utilities/modals/search/select-location.html">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Select Location</span>
                                                    </a>
                                                    {/* <!--end:Menu link-->*/}
                                                </div>
                                                {/* <!--end:Menu item-->*/}
                                            </div>
                                            {/* <!--end:Menu sub-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                    </div>
                                    {/* <!--end:Menu sub-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                    {/* <!--begin:Menu link-->*/}
                                    <span className="menu-link">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Search</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    {/* <!--end:Menu link-->*/}
                                    {/* <!--begin:Menu sub-->*/}
                                    <div className="menu-sub menu-sub-accordion">
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/search/horizontal.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Horizontal</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/search/vertical.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Vertical</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/search/users.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Users</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/search/select-location.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Location</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                    </div>
                                    {/* <!--end:Menu sub-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                    {/* <!--begin:Menu link-->*/}
                                    <span className="menu-link">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Wizards</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    {/* <!--end:Menu link-->*/}
                                    {/* <!--begin:Menu sub-->*/}
                                    <div className="menu-sub menu-sub-accordion">
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/wizards/horizontal.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Horizontal</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/wizards/vertical.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Vertical</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/wizards/two-factor-authentication.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Two Factor Auth</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/wizards/create-app.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Create App</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/wizards/create-campaign.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Create Campaign</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/wizards/create-account.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Create Account</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/wizards/create-project.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Create Project</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/modals/wizards/top-up-wallet.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Top Up Wallet</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                        {/* <!--begin:Menu item-->*/}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link-->*/}
                                            <a className="menu-link" href="utilities/wizards/offer-a-deal.html">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Offer a Deal</span>
                                            </a>
                                            {/* <!--end:Menu link-->*/}
                                        </div>
                                        {/* <!--end:Menu item-->*/}
                                    </div>
                                    {/* <!--end:Menu sub-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                            </div>
                            {/* <!--end:Menu sub-->*/}
                        </div>
                        {/* <!--end:Menu item-->*/}
                        {/* <!--begin:Menu item-->*/}
                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                            {/* <!--begin:Menu link-->*/}
                            <span className="menu-link">
                                <span className="menu-icon">
                                    <i className="ki-duotone ki-element-7 fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </span>
                                <span className="menu-title">Widgets</span>
                                <span className="menu-arrow"></span>
                            </span>
                            {/* <!--end:Menu link-->*/}
                            {/* <!--begin:Menu sub-->*/}
                            <div className="menu-sub menu-sub-accordion">
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="widgets/lists.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Lists</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="widgets/statistics.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Statistics</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="widgets/charts.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Charts</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="widgets/mixed.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Mixed</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="widgets/tables.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Tables</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                                {/* <!--begin:Menu item-->*/}
                                <div className="menu-item">
                                    {/* <!--begin:Menu link-->*/}
                                    <a className="menu-link" href="widgets/feeds.html">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Feeds</span>
                                    </a>
                                    {/* <!--end:Menu link-->*/}
                                </div>
                                {/* <!--end:Menu item-->*/}
                            </div>
                            {/* <!--end:Menu sub-->*/}
                        </div>
                        {/* <!--end:Menu item-->*/}

                    </div>
                    {/* <!--end::Menu-->*/}
                </div>
                {/* <!--end::Scroll wrapper-->*/}
            </div>
            {/* <!--end::Menu wrapper-->*/}
        </div>
        {/* <!--end::sidebar menu-->*/}
        {/* <!--begin::Footer-->*/}
        <div className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">
            <a href="https://preview.keenthemes.com/html/metronic/docs" className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss-="click" title="200+ in-house components and 3rd-party plugins">
                <span className="btn-label">Docs & Components</span>
                <i className="ki-duotone ki-document btn-icon fs-2 m-0">
                    <span className="path1"></span>
                    <span className="path2"></span>
                </i>
            </a>
        </div>
        {/* <!--end::Footer-->*/}

    </div>
    );
};

export default Sidebar;        